<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="edit">
        <flixDashboard v-if="!edit">
          <template v-slot:content>
            <div class="flix-container">
              <div class="flix-form-group">
                <select class="flix-form-control" v-model="selected">
                  <option v-for="(page, index) in pages" :key="index" :value="index">{{ page }}</option>
                </select>
              </div>
              <div class="flix-form-group">
                <a href="#" class="flix-btn flix-btn-default" @click="edit = selected">{{ $t('message.edit', {name: ''}) }}</a>
              </div>
            </div>
          </template>
        </flixDashboard>
        <pageBuilder :edit="edit" :onClose="function () { edit = false }" v-else />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    pageBuilder () { return import('@/components/pageBuilder/frontendPages') }
  },
  props: {},
  data () {
    return {
      selected: 'home',
      pages: {
        home: 'Home',
        quickstart: 'Quickstart',
        tools: 'Tools',
        price: 'Preise',
        ical: 'Ical-Hilfe',
        support: 'Support & Blog',
        'best-practice': 'Best Practice',
        privacy: 'Datenschutz',
        imprint: 'Impressum',
        terms: 'AGB'
      },
      edit: false
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
